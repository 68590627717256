//@ts-check
import { useCallback, useEffect, useRef, useState } from 'react'
import { prop } from 'ramda'
import adapter from 'webrtc-adapter'
import getLocalStream, { getConstraints } from 'utils/getLocalStream'
import { useNotificationActions } from 'context/NotificationProvider'
import config from 'config'
import Anchor from 'components/anchor/Anchor'

const initialAux = {
  audioInputAux: [],
  audioOutputAux: [],
  videoInputAux: []
}
const initialState = {
  audioInputId: '',
  audioOutputId: '',
  videoInputId: '',
  stopVideo: false
}

function makeInitialState() {
  const {
    audioInputId = '',
    audioOutputId = '',
    videoInputId = '',
    stopVideo = false
  } = JSON.parse(localStorage.getItem(config.selectedMediaKey)) || initialState
  return {
    audioInputId,
    audioOutputId,
    videoInputId,
    stopVideo
  }
}

/**
 * @typedef SelectedMedia
 * @property {string} audioInputId
 * @property {string} audioOutputId
 * @property {string} videoInputId
 * @property {boolean} stopVideo
 */

/**
 * @typedef MediaSetupState
 * @property {boolean} isLoading
 * @property {React.MutableRefObject<any>} mediaRef
 * @property {React.MutableRefObject<HTMLElement|any>} micLevelRef
 * @property {React.MutableRefObject<any>} audioContextRef
 * @property {string[]} audioInputs
 * @property {string[]} audioOutputs
 * @property {string[]} videoInputs
 * @property {SelectedMedia} selectedMedia
 * @property {(selectedMedia?: Partial<SelectedMedia>) => void} start
 * @property {(event: React.ChangeEvent<HTMLSelectElement>) => void} handleChange
 */

/**
 *
 * @returns {MediaSetupState}
 */
export default function useMediaSetup() {
  const mediaRef = useRef(null)
  const micLevelRef = useRef(null)
  const audioContextRef = useRef(null)
  const analyzerRef = useRef(null)
  const { setErrorMessage } = useNotificationActions()
  const [isLoading, setIsLoading] = useState(false)
  const [audioInputs, setAudioInputs] = useState([])
  const [audioOutputs, setAudioOutputs] = useState([])
  const [videoInputs, setVideoInputs] = useState([])
  const [selectedMedia, setSelectedMedia] = useState(makeInitialState)

  const handleError = useCallback(
    error => {
      console.error(
        [
          'navigator.MediaDevices.getUserMedia error:',
          error?.name,
          error?.message
        ].join(' -> ')
      )
      setErrorMessage({
        title: 'Ha ocurrido un error otorgando permisos',
        message: (
          <div>
            Revisa nuestra{' '}
            <Anchor
              target='_blank'
              href='https://classfy.notion.site/Problemas-micr-fono-134c4608ad678072a201cc6d6861b26a'
            >
              guía de principales errores
            </Anchor>{' '}
            o pide ayuda si persiste el error
          </div>
        ),
        ms: 10000
      })
      setSelectedMedia(initialState)
      return initialAux
    },
    [setErrorMessage]
  )

  const attachSinkId = sinkId => {
    if (sinkId && typeof mediaRef.current.sinkId !== 'undefined') {
      mediaRef.current.setSinkId(sinkId).catch(error => {
        let errorMessage = error
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
        }
        console.error(errorMessage)
      })
    } else {
      console.warn(
        'Browser does not support output device selection or sinkId is not defined'
      )
    }
  }

  const handleChange = e =>
    setSelectedMedia(state => {
      const newState = { ...state, [e.target.name]: e.target.value }
      if (e.target.name === 'audioOutputId') attachSinkId(e.target.value)
      return newState
    })

  const gotDevices = useCallback(
    deviceInfos => {
      let audioInputAux = []
      let audioOutputAux = []
      let videoInputAux = []
      setAudioInputs([])
      setAudioOutputs([])
      setVideoInputs([])
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        let option = { id: deviceInfo.deviceId, value: deviceInfo.deviceId }
        if (deviceInfo.kind === 'audioinput') {
          option.label =
            deviceInfo.label || `microphone ${audioInputs.length + 1}`
          audioInputAux.push(option)
        } else if (deviceInfo.kind === 'audiooutput') {
          option.label =
            deviceInfo.label || `speaker ${audioOutputs.length + 1}`
          audioOutputAux.push(option)
        } else if (deviceInfo.kind === 'videoinput') {
          option.label = deviceInfo.label || `camera ${videoInputs.length + 1}`
          videoInputAux.push(option)
        } else {
          console.log('Some other kind of source/device: ', deviceInfo)
        }
      }
      setAudioInputs(audioInputAux)
      setAudioOutputs(audioOutputAux)
      setVideoInputs(videoInputAux)
      return {
        audioInputAux,
        audioOutputAux,
        videoInputAux
      }
    },
    [audioInputs, audioOutputs, videoInputs]
  )

  const gotStream = useCallback((stream, audioOutputId) => {
    mediaRef.current.srcObject = stream
    if (audioOutputId) attachSinkId(audioOutputId)
    return navigator.mediaDevices.enumerateDevices()
  }, [])

  const startMonitoring = useCallback(async () => {
    try {
      if (!mediaRef.current) return
      audioContextRef.current = new AudioContext()
      analyzerRef.current = audioContextRef.current.createAnalyser()
      analyzerRef.current.fftSize = 256

      const source = audioContextRef.current.createMediaStreamSource(
        mediaRef.current.srcObject
      )
      source.connect(analyzerRef.current)

      const dataArray = new Uint8Array(analyzerRef.current.frequencyBinCount)

      const updateVolume = () => {
        if (micLevelRef.current) {
          analyzerRef.current.getByteFrequencyData(dataArray)
          const average =
            dataArray.reduce((a, b) => a + b, 0) / dataArray.length
          const normalizedVolume = Math.pow(average / 255, 0.25) * 100

          micLevelRef.current.style.width = `${Math.round(normalizedVolume)}%`
        }

        requestAnimationFrame(updateVolume)
      }

      updateVolume()
    } catch (error) {
      setErrorMessage({
        title: 'Ha ocurrido un error accediendo al micrófono',
        message: (
          <div>
            Revisa nuestra{' '}
            <Anchor
              target='_blank'
              href='https://classfy.notion.site/Problemas-micr-fono-134c4608ad678072a201cc6d6861b26a'
            >
              guía de principales errores
            </Anchor>{' '}
            o pide ayuda si persiste el error
          </div>
        ),
        ms: 10000
      })
      console.error('No se pudo acceder al micrófono:', error)
    }
  }, [setErrorMessage])

  const start = useCallback(() => {
    if (!mediaRef.current) return Promise.resolve(initialAux)
    setIsLoading(true)
    const { audioInputId, videoInputId, audioOutputId } = selectedMedia

    if (mediaRef.current.srcObject)
      mediaRef.current.srcObject.getTracks().forEach(track => track.stop())

    return getLocalStream(getConstraints(audioInputId, videoInputId))
      .then(stream => gotStream(stream, audioOutputId))
      .then(gotDevices)
      .catch(err => {
        console.error(
          'Error connecting with LocalStream (selectedMedia): %o, trying default...',
          err
        )
        return getLocalStream(getConstraints())
          .then(stream => gotStream(stream, audioOutputId))
          .then(gotDevices)
          .catch(err => {
            console.error(
              'Error connecting with LocalStream (default): %o, trying selected audio...',
              err
            )
            return getLocalStream({
              audio: audioInputId
                ? { deviceId: { exact: audioInputId } }
                : adapter.browserDetails.browser === 'chrome'
                ? { deviceId: { exact: 'default' } }
                : true
            })
              .then(stream => gotStream(stream, audioOutputId))
              .then(gotDevices)
              .catch(err => {
                console.error(
                  'Error connecting with LocalStream (selected audio): %o, trying default audio...',
                  err
                )
                return getLocalStream({
                  audio:
                    adapter.browserDetails.browser === 'chrome'
                      ? { deviceId: { exact: 'default' } }
                      : true
                })
                  .then(stream => gotStream(stream, audioOutputId))
                  .then(gotDevices)
                  .catch(handleError)
              })
          })
      })
      .finally(() => {
        setIsLoading(false)
        startMonitoring()
      })
  }, [gotDevices, gotStream, handleError, selectedMedia, startMonitoring])

  useEffect(() => {
    const checkDevices = async () => {
      start()
        .then(({ audioInputAux, audioOutputAux, videoInputAux }) =>
          setSelectedMedia(prev => {
            let newSelectedMedia = { ...prev }
            if (!audioInputAux.map(prop('id')).includes(prev.audioInputId))
              newSelectedMedia.audioInputId = ''
            if (!audioOutputAux.map(prop('id')).includes(prev.audioOutputId))
              newSelectedMedia.audioOutputId = ''
            if (!videoInputAux.map(prop('id')).includes(prev.videoInputId))
              newSelectedMedia.videoInputId = ''
            return newSelectedMedia
          })
        )
        .catch(handleError)
    }
    navigator.mediaDevices.addEventListener('devicechange', checkDevices)
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', checkDevices)
    }
  }, [handleError, selectedMedia, start])

  useEffect(() => {
    const ref = mediaRef.current
    const audioCtxRef = audioContextRef?.current
    start()
    return () => {
      if (ref?.srcObject)
        ref.srcObject.getTracks().forEach(track => {
          track.stop()
        })
      if (audioCtxRef && audioCtxRef.state !== 'closed') audioCtxRef.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedia])

  return {
    isLoading,
    mediaRef,
    micLevelRef,
    audioContextRef,
    audioInputs,
    audioOutputs,
    videoInputs,
    selectedMedia,
    start,
    handleChange
  }
}
