import * as React from 'react'

const Error = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || 'var(--dark-grey-40)'}
      d='M8 1C4.129 1 1 4.129 1 8s3.129 7 7 7 7-3.129 7-7-3.129-7-7-7zM4 7h8v2H4zm0 0'
    />
  </svg>
)
export default Error
