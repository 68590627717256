import React, { memo, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { noop } from 'utils'
import { findTemporaryNotebooks } from 'api/files'
import Vector from 'assets/icons/Vector'
import Select from '../Select'
import styles from './TemporaryNotebookSelect.module.css'

const customStyles = {
  select: styles.customSelect
}

function TemporaryNotebookSelect({ onSelect = noop }) {
  const [isFetching, setIsFetching] = useState(true)
  const [notebooks, setNotebooks] = useState([])

  useEffect(() => {
    setIsFetching(true)
    findTemporaryNotebooks()
      .then(setNotebooks)
      .catch(console.error)
      .finally(() => setIsFetching(false))
  }, [])
  if ((isFetching && !notebooks) || isEmpty(notebooks)) return null
  return (
    <div className={styles.notebookSelect}>
      <Select
        label='Cuadernillo subido'
        placeholder='Selecciona cuadernillo'
        valueKey='url'
        isLoading={isFetching}
        options={notebooks}
        onChange={e => {
          const { label, url } = notebooks.find(n => n.url === e.target.value)
          onSelect(url, label)
        }}
        customStyles={customStyles}
      />
      <Vector color='var(--seadapted)' className={styles.vectorIcon} />
    </div>
  )
}

export default memo(TemporaryNotebookSelect)
