import React, { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from 'utils'
import { AttendanceTypes } from 'utils/constants'
import BrainOn from 'assets/icons/BrainOn'
import Doubt from 'assets/icons/Doubt'
import Learning from 'assets/icons/Learning'
import Understood from 'assets/icons/Understood'
import ChatIcon from 'assets/icons/ChatIcon'
import { Paragraph } from 'components/typography'
import DotsSpinner from 'components/spinners/DotsSpinner'
import VideoSource from '../VideoSource'
import Timer from '../Timer'
import ClassroomLightbulb from '../Lightbulb'
import audio from './resources/sound.mp3'
import styles from './StudentVideoSource.module.css'

export const LightbulbIds = {
  lightbulb: 'lightbulb',
  learning: 'learning',
  doubt: 'doubt',
  brainOn: 'brainOn',
  understood: 'understood'
}

export const TooltipTextByLightbulbId = {
  lightbulb: 'Help ☝️!',
  learning: 'Aprendiendo 😢',
  doubt: 'Tengo una duda 🤔',
  brainOn: '🧠 ON',
  understood: 'Entendido 🤙🏻'
}

const CHAT_ID = 'chatId'

const LightbulbIconByType = {
  [LightbulbIds.lightbulb]: (
    <ClassroomLightbulb
      classNames={{
        container: styles.lightbulbContainer,
        innerContainer: styles.lightbulbInnerContainer
      }}
    />
  ),
  [LightbulbIds.learning]: (
    <div className={styles.wrapperIcon}>
      <Learning className={styles.icon} />
    </div>
  ),
  [LightbulbIds.doubt]: (
    <div className={styles.wrapperIcon}>
      <Doubt className={styles.icon} />
    </div>
  ),
  [LightbulbIds.brainOn]: (
    <div className={styles.wrapperIcon}>
      <BrainOn className={styles.icon} />
    </div>
  ),
  [LightbulbIds.understood]: (
    <div className={styles.wrapperIcon}>
      <Understood className={styles.icon} />
    </div>
  )
}
const backgroundByType = {
  [AttendanceTypes.PRUEBA]: 'var(--sandground-50)',
  [AttendanceTypes.FORMACION]: 'var(--accent-color-50)',
  default: 'var(--seadapted-50)'
}
const tooltipBackgroundByType = {
  [AttendanceTypes.PRUEBA]: 'var(--sandground)',
  [AttendanceTypes.FORMACION]: 'var(--accent-color)',
  default: 'var(--seadapted)'
}

function StudentVideoSource({
  student = {},
  lightbulbIdsByStudent = {},
  activeStudent = '',
  isDoubleColumn = false,
  activeLightbulb = false,
  isReconnecting = false,
  showChatAlert = false,
  stream,
  selectedMedia,
  onClick = noop,
  onOpenChat = noop,
  onSetTimer = noop
}) {
  const tooltipRef = useRef(null)
  const tooltip = tooltipRef.current
  const parentRef = useRef(null)
  const parent = parentRef.current
  const videoRef = useRef(null)
  const audioRef = useRef(null)

  const [timer, setTimer] = useState({ idle: 0, active: false })
  const [lightbulbTimer, setLightbulbTimer] = useState({
    idle: 0,
    active: false
  })
  const isStudentActive = activeStudent === student._id
  const isTest = student.attendance?.type === AttendanceTypes.PRUEBA
  const isTrainee = student.attendance?.type === AttendanceTypes.FORMACION
  const studentLightbulbId = lightbulbIdsByStudent[student._id]

  const handleClick = e => {
    if (CHAT_ID == e.target.id) return onOpenChat()
    onClick()
  }
  const parentBackgroundColor =
    backgroundByType[student.attendance?.type] || backgroundByType.default
  const lateralTooltipBackgroundColor =
    tooltipBackgroundByType[student.attendance?.type] ||
    tooltipBackgroundByType.default

  const displayTooltip = () => {
    if (!tooltip || !parent) return
    parent.style.setProperty('--parent-color', parentBackgroundColor)

    const tooltipMargin = 10
    const parentRect = parent.getBoundingClientRect()
    parent.style.setProperty('--tooltip-color', lateralTooltipBackgroundColor)

    tooltip.style.display = 'inline-flex'
    const tooltipRect = tooltip.getBoundingClientRect()
    tooltip.style.left = parentRect.x + parentRect.width + tooltipMargin + 'px'

    const offset =
      parentRect.height > tooltipRect.height
        ? Math.abs((tooltipRect.height - parentRect.height) / 2)
        : -(tooltipRect.height - parentRect.height) / 2

    tooltip.style.top = parentRect.y + offset + 'px'
    tooltip.style.position = 'fixed'
  }

  const hideTooltip = useCallback(() => {
    if (tooltip) tooltip.style.display = 'none'
  }, [tooltip])

  useEffect(() => {
    if (isStudentActive) setTimer({ idle: 0, active: false })
    else setTimer({ idle: 0, active: true })
  }, [isStudentActive])

  useEffect(() => {
    if (activeLightbulb) setLightbulbTimer({ idle: 0, active: true })
    if (isStudentActive) setLightbulbTimer({ idle: 0, active: false })
    else setLightbulbTimer({ idle: 0, active: true })
  }, [activeLightbulb, isStudentActive])

  useEffect(() => {
    if (!audioRef.current) audioRef.current = new Audio()

    const sound = audioRef.current

    if (selectedMedia.audioOutputId && typeof sound.setSinkId !== 'undefined')
      sound.setSinkId(selectedMedia.audioOutputId).catch(error => {
        console.error('Error setting sinkId for sound:', error)
      })
    else console.warn('Browser does not support audio output device selection.')
  }, [selectedMedia.audioOutputId])

  useEffect(() => {
    if (!activeLightbulb) return

    const sound = audioRef.current
    if (sound) {
      sound.src = audio
      sound.currentTime = 0
      sound.play().catch(error => console.error('Error playing sound:', error))
    }
  }, [activeLightbulb])

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer(current =>
        current.active ? { idle: current.idle + 1, active: true } : current
      )
    }, 1000)
    return () => clearInterval(timerInterval)
  }, [])

  useEffect(() => {
    const timerBInterval = setInterval(() => {
      setLightbulbTimer(prevTimerB =>
        prevTimerB.active
          ? {
              idle: prevTimerB.idle + 1,
              active: true
            }
          : prevTimerB
      )
    }, 1000)

    return () => clearInterval(timerBInterval)
  }, [])

  useEffect(() => {
    onSetTimer(timer.idle)
  }, [onSetTimer, timer])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        hideTooltip()
      },
      { threshold: 0.1 }
    )

    if (parent) {
      observer.observe(parent)
    }

    return () => {
      if (parent) {
        observer.unobserve(parent)
      }
    }
  }, [hideTooltip, parent])

  useEffect(() => {
    if (stream && videoRef && videoRef.current) {
      videoRef.current.srcObject = stream
    }
    if (!selectedMedia.audioOutputId) return
    const attachSinkId = sinkId => {
      if (typeof videoRef.current.sinkId !== 'undefined') {
        videoRef.current.setSinkId(sinkId).catch(error => {
          let errorMessage = error
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
          }
          console.error(errorMessage)
        })
      } else {
        console.warn('Browser does not support output device selection.')
      }
    }
    attachSinkId(selectedMedia.audioOutputId)
  }, [stream, selectedMedia.audioOutputId])

  return (
    <div
      ref={parentRef}
      className={[
        styles.container,
        isReconnecting ? styles.reconnecting : ''
      ].join(' ')}
      onClick={handleClick}
      onMouseEnter={e => {
        displayTooltip(e)
        displayTooltip(e)
      }}
      onMouseLeave={hideTooltip}
    >
      <div className={styles.timer}>
        <Timer seconds={timer.idle} active={isStudentActive} />
      </div>
      <VideoSource
        color={
          isReconnecting
            ? 'error'
            : isTest
            ? 'tertiary'
            : isTrainee
            ? 'quaternary'
            : 'secondary'
        }
        ref={videoRef}
        size={isStudentActive ? 'large' : isDoubleColumn ? 'small' : 'medium'}
        disabled={!isStudentActive}
        muted={!isStudentActive}
      />
      {activeLightbulb && (
        <>
          {LightbulbIconByType[studentLightbulbId]}
          <div className={styles.lightbulbTimer}>
            <Timer
              seconds={lightbulbTimer.idle}
              active={isStudentActive}
              color='var(--success-color)'
              size='small'
            />
          </div>
        </>
      )}
      <div className={styles.innerBackground}>
        {isReconnecting && <DotsSpinner />}
      </div>
      {showChatAlert && (
        <div
          id={CHAT_ID}
          className={styles.containerChatIcon}
          onClick={handleClick}
        >
          <ChatIcon
            id={CHAT_ID}
            color='var(--error-color-50)'
            className={styles.chatIcon}
          />
        </div>
      )}
      <div ref={tooltipRef} className={styles.lateralTooltip}>
        <Paragraph type='body1Bold' className={styles.student}>
          {isTest && (
            <>
              ¡¡¡NUEVO PADAWAN!!!
              <br />
            </>
          )}
          {student.name}
          <br />
          {getAttendanceInfo(student.attendance, studentLightbulbId)}
        </Paragraph>
      </div>
    </div>
  )
}

export default StudentVideoSource

function getAttendanceInfo(attendance, lightbulbId = LightbulbIds.brainOn) {
  if (!attendance) return null
  let text =
    TooltipTextByLightbulbId[lightbulbId] || TooltipTextByLightbulbId.brainOn
  if (attendance?.subjectName) text += ' - ' + attendance.subjectName
  if (attendance?.subjectLevel) text += ' de ' + attendance.subjectLevel
  if (attendance?.subjectSublevel)
    text += ' (' + attendance.subjectSublevel + ')'
  return text
}
