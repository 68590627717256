//@ts-check
import React, { useCallback } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import useNavigation from 'hooks/useNavigation'
import useLocalStorage from 'hooks/useLocalStorage'
import useMediaSetup from 'hooks/useMediaSetup'
import config from 'config'
import Phone from 'assets/svg/Phone'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import VideoSource from 'components/academy/classroom/VideoSource'
import Select from 'components/selects/Select'
import Anchor from 'components/anchor/Anchor'
import styles from './MediaPermissions.module.css'

function MediaPermissions() {
  /**@type {import('hooks/useWebRTC').WebRTCState} */
  const { saveSelectedMedia } = useOutletContext()
  const { saveItem } = useLocalStorage(config.hasMediaPermissionsKey, false)
  const navigate = useNavigation()
  const {
    isLoading,
    mediaRef,
    micLevelRef,
    audioInputs,
    audioOutputs,
    videoInputs,
    selectedMedia,
    handleChange
  } = useMediaSetup()

  const handleClick = useCallback(async () => {
    saveSelectedMedia(selectedMedia)
    saveItem(true)
    navigate('/academy')
  }, [navigate, saveItem, saveSelectedMedia, selectedMedia])

  const hasAudioOutput = 'sinkId' in HTMLMediaElement.prototype
  const disabled =
    !selectedMedia.audioInputId ||
    (hasAudioOutput ? !selectedMedia.audioOutputId : false) ||
    isLoading

  return (
    <section className={styles.section}>
      <Link to='/'>
        <BrightClassfy className={styles.classfyLogo} />
      </Link>
      <div className={styles.video}>
        <VideoSource color='secondary' size='large' ref={mediaRef} />
      </div>
      <H2 className={styles.h2}>
        Antes de nada habrá que <span className={styles.seadapted}>verse</span>{' '}
        y <br />
        <span className={styles.sandground}>escucharse</span> 😝
        <div className={styles.images}>
          <Phone className={styles.backgroundSvg} color='var(--sandground)' />
          <Phone className={styles.mainSvg} />
        </div>
      </H2>
      <Paragraph className={styles.description}>
        Configura tu cámara y tu micrófono para que tu clase sea una experiencia
        sin cortes
      </Paragraph>
      <div
        className={[
          styles.setupSection,
          !hasAudioOutput ? styles.setupSectionTwoColumns : ''
        ].join(' ')}
      >
        <Select
          name='audioInputId'
          label={
            <div className={styles.micLabel}>
              Micrófono
              <div className={styles.micLevelMeter}>
                <div ref={micLevelRef} className={styles.micLevelBar} />
              </div>
            </div>
          }
          customStyles={{ label: styles.selectLabel }}
          options={audioInputs}
          value={selectedMedia.audioInputId}
          isLoading={isLoading}
          onChange={handleChange}
        />
        {hasAudioOutput && (
          <Select
            name='audioOutputId'
            label='Altavoz'
            customStyles={{ label: styles.selectLabel }}
            options={audioOutputs}
            value={selectedMedia.audioOutputId}
            isLoading={isLoading}
            onChange={handleChange}
          />
        )}
        <Select
          name='videoInputId'
          label='Cámara'
          customStyles={{ label: styles.selectLabel }}
          options={videoInputs}
          value={selectedMedia.videoInputId}
          isLoading={isLoading}
          onChange={handleChange}
        />
      </div>
      <Button
        label='Dar acceso'
        type='secondary'
        disabled={disabled}
        loading={isLoading}
        onClick={handleClick}
      />

      <Paragraph className={styles.hint}>
        Prueba nuestra guía de principales errores al otrogar permisos:{' '}
        <Anchor
          type='secondary'
          target='_blank'
          href='https://classfy.notion.site/Problemas-micr-fono-134c4608ad678072a201cc6d6861b26a'
        >
          ir a la guía
        </Anchor>
      </Paragraph>
    </section>
  )
}

export default MediaPermissions
