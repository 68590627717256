const QUALITY = 0.7
export function compressImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = event => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')

        canvas.width = img.width
        canvas.height = img.height

        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height)

        const format = file.type === 'image/png' ? 'image/png' : 'image/jpeg'
        canvas.toBlob(
          blob => {
            if (blob) {
              resolve({ compressedFile: blob, image: event.target.result })
            } else {
              reject(new Error('Compression failed.'))
            }
          },
          format,
          QUALITY
        )
      }
      img.onerror = () => reject(new Error('Failed to load the image.'))
      img.src = event.target.result
    }
    reader.onerror = () => reject(new Error('Failed to read the file.'))
    reader.readAsDataURL(file)
  })
}

export function imageUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const mimeType = getMimeTypeFromUrl(url) || 'image/png'
      const dataURL = canvas.toDataURL(mimeType)
      resolve(dataURL.split(',')[1])
    }

    img.onerror = err =>
      reject(new Error('Error al cargar la imagen: ' + err.message))
    img.src = `${url}?v=${new Date().getTime()}`
  })
}

const mimeTypes = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',
  txt: 'text/plain',
  html: 'text/html',
  css: 'text/css',
  js: 'application/javascript',
  json: 'application/json',
  mp4: 'video/mp4',
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
  zip: 'application/zip'
}
export function getMimeTypeFromUrl(url) {
  const extension = url.split('.').pop().toLowerCase()
  return mimeTypes[extension]
}
