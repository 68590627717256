//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { noop } from 'utils'
import Cross from 'assets/icons/Cross'
import ChevronRight from 'assets/icons/ChevronRight'
import ChevronLeft from 'assets/icons/ChevronLeft'
import styles from './GalleryModal.module.css'

function GalleryModal({
  initialIndex = null,
  photos = [],
  tabIndex = 102,
  onCancel = noop
}) {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(initialIndex)
  const modalRef = useRef(null)

  const handleNext = () =>
    setSelectedPhotoIndex(prevIndex => (prevIndex + 1) % photos.length)

  const handlePrev = () =>
    setSelectedPhotoIndex(
      prevIndex => (prevIndex - 1 + photos.length) % photos.length
    )
  const handleKeyDown = e => {
    if (e.key === 'Escape') return onCancel()
    if (e.key === 'ArrowLeft') return handlePrev()
    if (e.key === 'ArrowRight') return handleNext()
  }

  useEffect(() => {
    modalRef.current?.focus()
  }, [])
  const hideArrows = photos.length < 2
  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className={styles.modal}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
    >
      <div className={styles.content}>
        <Cross
          className={styles.closeIcon}
          color='var(--dark-grey-40)'
          onClick={onCancel}
        />
        {!hideArrows && (
          <ChevronLeft
            id={String(selectedPhotoIndex)}
            className={`${styles.arrow} ${styles.leftArrow}`}
            onClick={handlePrev}
            color='var(--bright-50)'
          />
        )}
        <div className={styles.selectedPhotoContainer}>
          <img
            src={photos[selectedPhotoIndex]?.location}
            alt={`Photo ${selectedPhotoIndex + 1}`}
            className={styles.selectedPhoto}
          />
        </div>
        {!hideArrows && (
          <ChevronRight
            id={String(selectedPhotoIndex)}
            className={`${styles.arrow} ${styles.rightArrow}`}
            onClick={handleNext}
            color='var(--bright-50)'
          />
        )}
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default GalleryModal
