export const emitIdentity = async ({ socket, data }) => {
  return new Promise((resolve, reject) => {
    socket.emit('connection:identity', data, res => {
      if (res.status !== 'OK') reject(res)
      else resolve(true)
    })
  })
}

export const reconnectSocket = ({ socket, data }) => {
  return new Promise((resolve, reject) => {
    socket.emit('reconnect-socket', data, res => {
      if (res.status !== 'OK') reject(res)
      else resolve(true)
    })
  })
}

export const sendLogMessage = async ({ socket, message }) => {
  return new Promise((resolve, reject) => {
    socket.emit('log:message', message, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendPeerSignal = async ({ socket, signal, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:peer-signal', { signal, student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendActiveStudent = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:select-student', student, res => {
      if (res.status !== 'OK') reject(res)
      else resolve(true)
    })
  })
}

export const sendWhiteboardLine = async ({
  socket,
  student,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:whiteboard-line',
      { student, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendWhiteboardType = async ({
  socket,
  student,
  type,
  numberOfLines,
  numberOfColumns
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:whiteboard-type',
      { student, type, numberOfLines, numberOfColumns },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}
export const sendNotebookWhiteboardType = async ({
  socket,
  student,
  type,
  numberOfLines,
  numberOfColumns
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-whiteboard-type',
      { student, type, numberOfLines, numberOfColumns },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendWhiteboardClear = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:whiteboard-clear', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendNotebookBlackboardLine = async ({
  socket,
  student,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-blackboard-line',
      { student, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendNotebookPdfLine = async ({
  socket,
  student,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-pdf-line',
      { student, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendNotebookClear = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:notebook-clear', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendNotebookChange = async ({
  socket,
  student,
  url,
  title,
  tmp = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-change',
      { student, url, title, tmp },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendNotebookChangePage = async ({ socket, student, page }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-page-change',
      { student, page },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendNotebookSyncPage = async ({ socket, student, page }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:notebook-page-sync',
      { student, page },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendVideoSelect = async ({ socket, student, url }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:video-select', { student, url }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendVideoRemove = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:video-remove', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendImageLine = async ({
  socket,
  student,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:image-line',
      { student, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendImageRotation = async ({
  socket,
  student,
  rotationDegree
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:image-rotation',
      { student, rotationDegree },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendImageBoardClear = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:image-clear', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendImageSelect = async ({ socket, student, image }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:image-select', { image, student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const requestImageList = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:image-list', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve(res.images)
    })
  })
}

export const hangUpCall = async ({ socket, student }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:hang-up-call', { student }, res => {
      if (res.status !== 'OK') reject()
      else resolve()
    })
  })
}

export const sendSelectedTabChange = async ({ socket, student, tabId }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:teachers:selected-tab', { student, tabId }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendChatMessage = async ({ socket, student, message }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:teachers:chat-message',
      { student, message },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}
