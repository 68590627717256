import React from 'react'
import config from 'config'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import useNavigation from 'hooks/useNavigation'
import useLocalStorage from 'hooks/useLocalStorage'
import { initialState } from 'hooks/useWebRTC'
import Button from 'components/buttons/Button'
import { H3, H4Light } from 'components/typography'
import MediaSetup from 'components/modals/MediaSetup'
import styles from './Home.module.css'

const Home = () => {
  const { saveItem: storeSelectedMedia } = useLocalStorage(
    config.selectedMediaKey,
    initialState.selectedMedia
  )
  const navigate = useNavigation()
  return (
    <section className={styles.section}>
      <div className={styles.card}>
        <DarkClassfy className={styles.cardImage} />
        <H3>
          Al entrar en clase aparecerás como disponible para los alumnos que se
          conecten y podrán iniciar una clase contigo a través de videollamada.
        </H3>
        <Button label='Entrar en clase' onClick={() => navigate('academy')} />
        <div className={styles.fabIconSection}>
          <H4Light>¿Quieres ajustar 🎤 📸?</H4Light>
          <MediaSetup
            className={styles.fabIcon}
            fabIconColor='var(--seadapted)'
            onSave={storeSelectedMedia}
          />
        </div>
      </div>
    </section>
  )
}
export default Home
