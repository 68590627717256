//@ts-check
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty, pick } from 'ramda'
import * as Sentry from '@sentry/react'
import io from 'socket.io-client'
import Peer from 'simple-peer'
import adapter from 'webrtc-adapter'
import { useLocation } from 'react-router-dom'
import getLocalStream, { getConstraints } from 'utils/getLocalStream'
import detector from 'utils/detector'
import config from 'config'
import { fetchICEServers } from 'api/iceConnection'
import { useAuth } from 'context/AuthProvider'
import {
  emitIdentity,
  reconnectSocket,
  sendLogMessage,
  sendPeerSignal
} from 'api/sockets'
import usePrevious from './usePrevious'
import useLocalStorage from './useLocalStorage'

let socket = null
let localPeerConnections = []
let localAuthenticated = false
const noop = (str = '') => {}
export const initialState = {
  iceServers: [],
  students: [],
  localStream: null,
  remoteStreams: [],
  authenticated: false,
  isReconnecting: false,
  blockNavigation: true,
  selectedMedia: {
    audioInputId: '',
    audioOutputId: '',
    videoInputId: '',
    stopVideo: false
  }
}
/**
 * @typedef WebRTCState
 * @property {Array} iceServers
 * @property {Array} students
 * @property {any} localStream
 * @property {any[]} remoteStreams
 * @property {import('./useMediaSetup').SelectedMedia} selectedMedia
 * @property {boolean} authenticated
 * @property {boolean} isReconnecting
 * @property {boolean} blockNavigation
 * @property {boolean} canConnectSocketEvents
 * @property {any} socket
 * @property {object} identity
 * @property {import('react-router-dom').Location} location
 * @property {() => Promise<any>} requestIceServers
 * @property {(constraints: object) => void} saveSelectedMedia
 * @property {() => Promise<any>} initUserMedia
 * @property {(selection: Partial<import('./useMediaSetup').SelectedMedia>) => Promise<any>} changeStream
 * @property {() => void} initSocketEvents
 * @property {() => void} prepareConnection
 * @property {() => void} endSocketEvents
 * @property {() => void} stopLocalStream
 */

/**
 *
 * @param {(str: string)=> void} onError
 * @returns {WebRTCState}
 */
export default function useWebRTC(onError = noop) {
  const [state, setState] = useState(initialState)
  const { getParsedItem: getVideoTourViewedValue } = useLocalStorage(
    config.isVideoTourViewedKey,
    false
  )
  const { getParsedItem: getHasMediaPermissionsValue } = useLocalStorage(
    config.hasMediaPermissionsKey,
    false
  )
  const { getParsedItem: getSelectedMediaValue, saveItem: storeSelectedMedia } =
    useLocalStorage(config.selectedMediaKey, initialState.selectedMedia)
  const {
    iceServers,
    students,
    localStream,
    remoteStreams,
    authenticated,
    isReconnecting,
    blockNavigation
  } = state
  const { teacher } = useAuth()
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const localStreamRef = useRef(null)

  const identity = useMemo(
    () =>
      pick(
        [
          '_id',
          'id',
          'name',
          'shortName',
          'accessType',
          'role',
          'device',
          'profileThumbnailUrl'
        ],
        {
          ...teacher,
          role: 'TEACHER',
          device: detector()
        }
      ),
    [teacher]
  )
  const canConnectSocketEvents = useMemo(
    () => !isEmpty(iceServers) && localStream,
    [iceServers, localStream]
  )

  const requestIceServers = useCallback(
    () =>
      fetchICEServers().catch(err => {
        console.error('Error connecting with ICEServers:', err)
        throw new Error('Error estableciendo videollamada')
      }),
    []
  )

  const saveSelectedMedia = selectedMedia => storeSelectedMedia(selectedMedia)

  const initUserMedia = useCallback(async () => {
    /** @type {WebRTCState['selectedMedia']} */
    const { audioInputId, videoInputId } = getSelectedMediaValue()
    return getLocalStream(getConstraints(audioInputId, videoInputId)).catch(
      err => {
        console.error(
          'Error connecting with LocalStream (selectedMedia): %o, trying default...',
          err
        )
        return getLocalStream(getConstraints()).catch(err => {
          console.error(
            'Error connecting with LocalStream (default): %o, trying selected audio...',
            err
          )
          return getLocalStream({
            audio: audioInputId
              ? { deviceId: { exact: audioInputId } }
              : adapter.browserDetails.browser === 'chrome'
              ? { deviceId: { exact: 'default' } }
              : true
          }).catch(err => {
            err => {
              console.error(
                'Error connecting with LocalStream (selected audio): %o, trying default audio...',
                err
              )
              //Try only audio
              return getLocalStream({
                audio:
                  adapter.browserDetails.browser === 'chrome'
                    ? { deviceId: { exact: 'default' } }
                    : true
              }).catch(err => {
                console.error(
                  'Error connecting with LocalStream (default audio):',
                  err
                )
                throw new Error('Error activando el dispositivo multimedia')
              })
            }
          })
        })
      }
    )
  }, [getSelectedMediaValue])

  const changeStream = async (selection = initialState.selectedMedia) => {
    try {
      if (!localStreamRef.current) return

      localStreamRef.current?.getTracks().forEach(track => track.stop())

      storeSelectedMedia(selection)
      let newStream = await initUserMedia()
      if (selection.stopVideo) {
        newStream.getVideoTracks().forEach(track => track.stop())
        newStream = new MediaStream(newStream.getAudioTracks())
      }

      localStreamRef.current = newStream
      setState(state => ({ ...state, localStream: newStream }))
      newStream.getTracks().forEach(track => {
        localPeerConnections.forEach(({ peerConnection }) =>
          peerConnection.addTrack(track, newStream)
        )
      })
    } catch (err) {
      console.error('Error changing stream:', err)
    }
  }

  const prepareConnection = useCallback(async () => {
    try {
      Sentry.setUser(identity)
      const iceServers = await requestIceServers()
      const localStream = await initUserMedia()
      setState(state => ({ ...state, iceServers, localStream }))
      localStreamRef.current = localStream
    } catch (error) {
      Sentry.captureException(error)
      onError(error.message)
    }
  }, [identity, requestIceServers, initUserMedia, onError])

  const connect = useCallback(async () => {
    if (socket.connected && !localAuthenticated) {
      config.logWebRTC && console.log('Conectado')
      config.logWebRTC && console.log('SOCKET ID: ', socket.id)

      try {
        await emitIdentity({
          socket,
          data: identity
        })
        config.logWebRTC && console.log('Identificado')
        setState(state => ({
          ...state,
          authenticated: true
        }))
        localAuthenticated = true
      } catch (e) {
        Sentry.captureException(e)
        console.error('Error in emitIdentity:', e)
        if (e.codeMessage === 'DUPLICATE_CONNECTION')
          return onError('Ya estás conectado desde otra pestaña o dispositivo.')
        if (e.codeMessage === 'INVALID_DATA')
          return onError('No se ha podido validar tu identidad.')
        else onError(e.message)
      }
    }
  }, [identity, onError])

  const reconnect = useCallback(async () => {
    try {
      config.logWebRTC &&
        console.log('Reconnecting... ', { localPeerConnections })
      await reconnectSocket({
        socket,
        data: {
          ...identity,
          classroom: { students: localPeerConnections.map(s => s._id) }
        }
      })
      config.logWebRTC && console.log('Reconnected: ', socket.id)
      setState(state => ({ ...state, isReconnecting: false }))
    } catch (error) {
      console.error('Error reconnecting: ', error)
      onError('Ha ocurrido un error reconectando')
    }
  }, [identity, onError])

  const peerSignal = useCallback(({ studentId, signal }) => {
    sendLogMessage({
      socket,
      message: `Successfully received signal data of type (${
        signal.type || 'candidate'
      }) from student=${studentId}`
    })
    const activeStudent = localPeerConnections.find(
      student => student._id === studentId
    )
    if (!activeStudent) {
      sendLogMessage({
        socket,
        message: `Got signal from student with id="${studentId}" but it's not my student`
      })
      return
    }
    activeStudent.peerConnection.signal(signal)
  }, [])

  const onAddStudent = useCallback(
    student => {
      try {
        const peerConnection = new Peer({
          config: { iceServers },
          stream: localStreamRef.current
        })
        peerConnection.on('signal', signal => {
          console.warn('Signal is of type:', signal.type || 'candidate')

          sendPeerSignal({
            socket,
            signal,
            student: student._id
          })
        })
        peerConnection.on('connect', () =>
          sendLogMessage({
            socket,
            message: `Successfully connected to student with id="${student._id}"`
          })
        )

        peerConnection.on('stream', remoteStream => {
          console.warn('Remote stream!')
          setState(currentState => ({
            ...currentState,
            remoteStreams: [
              ...currentState.remoteStreams.filter(
                stream => stream._id !== student._id
              ),
              { _id: student._id, stream: remoteStream }
            ]
          }))
        })

        const onWebRTCTerminate = e => {
          console.error('Error with WebRTC: ', e || 'see back logs')
          Sentry.captureException(e)
          if (socket) {
            const peerToDestroy = localPeerConnections.find(
              p => p._id === student._id
            )
            if (peerToDestroy) {
              try {
                peerToDestroy.peerConnection.destroy()
              } catch (error) {
                console.error(
                  '[onWebRTCTerminate]:Error removing student and his peerConnection:',
                  error
                )
              }
            }
            setState(currentState => ({
              ...currentState,
              students: [
                ...currentState.students.filter(
                  currentStudent => currentStudent._id !== student._id
                )
              ],
              remoteStreams: [
                ...currentState.remoteStreams.filter(
                  stream => stream._id !== student._id
                )
              ]
            }))
            localPeerConnections = [
              ...localPeerConnections.filter(
                connection => connection._id !== student._id
              )
            ]
          }
        }

        peerConnection.on('error', onWebRTCTerminate)
        peerConnection.on('close', () =>
          onWebRTCTerminate('Peer connection closed')
        )
        const peerToDestroy = localPeerConnections.find(
          p => p._id === student._id
        )
        if (peerToDestroy) {
          try {
            peerToDestroy.peerConnection.destroy()
          } catch (error) {
            console.error(
              '[onAddStudent]: Error removing student and his peerConnection:',
              error
            )
          }
        }
        setState(currentState => ({
          ...currentState,
          students: [
            ...currentState.students.filter(
              currentStudent => currentStudent._id !== student._id
            ),
            student
          ]
        }))
        localPeerConnections = [
          ...localPeerConnections.filter(
            connection => connection._id !== student._id
          ),
          { _id: student._id, peerConnection }
        ]
      } catch (error) {
        console.error('[onAddStudent]:', error)
      }
    },
    [iceServers]
  )

  const onRemoveStudent = student => {
    const peerToDestroy = localPeerConnections.find(p => p._id === student._id)
    if (peerToDestroy) {
      try {
        peerToDestroy.peerConnection.destroy()
      } catch (error) {
        console.error(
          '[onRemoveStudent]: Error removing student and his peerConnection:',
          error
        )
      }
    }
    setState(currentState => ({
      ...currentState,
      students: [
        ...currentState.students.filter(
          currentStudent => currentStudent._id !== student._id
        )
      ],
      remoteStreams: [
        ...currentState.remoteStreams.filter(
          stream => stream._id !== student._id
        )
      ]
    }))
    localPeerConnections = [
      ...localPeerConnections.filter(
        connection => connection._id !== student._id
      )
    ]
  }
  const disconnect = useCallback(
    reason => {
      console.warn('Disconnected: ', reason)
      if (
        reason.includes('ping') ||
        reason.includes('transport close') ||
        reason.includes('transport error')
      ) {
        setState(state => ({ ...state, isReconnecting: true }))
        return
      }
      Sentry.captureMessage(
        'Received disconnection event from the API:' + reason
      )
      setState(state => ({ ...state, blockNavigation: false }))
      setTimeout(() => {
        onError('Se ha perdido la conexión con Classfy')
      }, 500)
    },
    [onError]
  )

  const initSocketEvents = useCallback(() => {
    try {
      if (!socket) socket = io(config.apiURL)

      config.logWebRTC && console.log('init socket events', !!socket)

      socket.on('connect', connect)
      socket.io.on('reconnect', reconnect)
      socket.on('academy:students:join', onAddStudent)
      socket.on('academy:students:leave', onRemoveStudent)
      socket.on('academy:students:disconnected', onRemoveStudent)
      socket.on('classroom:teachers:peer-signal', peerSignal)
      socket.on('disconnect', disconnect)
      socket.on('connect_error', e => console.error('connect_error: ', e))
      socket.io.on('close', () => console.warn(`CLOSED SOCKET!`))
    } catch (error) {
      console.error('[initSocketEvents]: ', error)
    }
  }, [connect, disconnect, onAddStudent, peerSignal, reconnect])

  const endSocketEvents = useCallback(() => {
    config.logWebRTC && console.log('endSocketEvents:', socket)
    if (!socket) return
    socket.off('classroom:teachers:peer-signal')
    socket.off('academy:students:leave')
    socket.off('academy:students:join')
    socket.off('academy:students:disconnected')
    socket.disconnect()
    socket = null
  }, [])

  const endPeerConnections = useCallback(() => {
    config.logWebRTC && console.log('endPeerConnections:', localPeerConnections)
    try {
      if (!!localPeerConnections.length) {
        localPeerConnections.forEach(connection =>
          connection.peerConnection.destroy()
        )
        localPeerConnections = []
      }
    } catch (error) {
      config.logWebRTC && console.error('endPeerConnections:', error)
    } finally {
      config.logWebRTC && console.log('endPeerConnections: cleaned')
    }
  }, [])

  const stopLocalStream = useCallback(() => {
    localStreamRef.current?.getTracks().forEach(track => track.stop())
  }, [])

  useEffect(() => {
    if (!canConnectSocketEvents || localAuthenticated) return
    initSocketEvents()
  }, [initSocketEvents, canConnectSocketEvents])

  useEffect(() => {
    if (
      getVideoTourViewedValue() &&
      getHasMediaPermissionsValue() &&
      location.pathname === '/academy'
    )
      prepareConnection()
  }, [
    getHasMediaPermissionsValue,
    getVideoTourViewedValue,
    prepareConnection,
    location.pathname
  ])

  useEffect(() => {
    return () => {
      config.logWebRTC && console.log('Unmount')
      localAuthenticated = false
      stopLocalStream()
      endSocketEvents()
      endPeerConnections()
    }
  }, [endPeerConnections, endSocketEvents, stopLocalStream])

  config.logWebRTC && console.log(location.pathname, previousLocation?.pathname)
  return {
    iceServers,
    students,
    localStream,
    remoteStreams,
    selectedMedia: getSelectedMediaValue(),
    authenticated,
    isReconnecting,
    blockNavigation,
    canConnectSocketEvents,
    socket,
    identity,
    location,
    requestIceServers,
    saveSelectedMedia,
    initUserMedia,
    changeStream,
    initSocketEvents,
    prepareConnection,
    endSocketEvents,
    stopLocalStream
  }
}
