import React, { useCallback, useState, useEffect } from 'react'
import {
  useNotification,
  useNotificationActions
} from 'context/NotificationProvider'
import Button from 'components/buttons/Button'
import Notification from './Notification'
import styles from './NotificationStack.module.css'

function NotificationStack() {
  const notifications = useNotification()
  const { clear } = useNotificationActions()
  const [exitingNotifications, setExitingNotifications] = useState([])

  const handleClose = useCallback(
    id => {
      setExitingNotifications(prev => [...prev, id])

      setTimeout(() => {
        clear(id)
        setExitingNotifications(prev => prev.filter(exitId => exitId !== id))
      }, 300)
    },
    [clear]
  )

  const handleCloseAll = () => {
    const allIds = notifications.map(({ id }) => id)
    setExitingNotifications(allIds)

    setTimeout(() => {
      allIds.forEach(id => clear(id))
      setExitingNotifications([])
    }, 300)
  }

  useEffect(() => {
    notifications.forEach(({ id, ms }) => {
      if (ms > 0) {
        const timeoutId = setTimeout(() => handleClose(id), ms - 300)
        return () => clearTimeout(timeoutId)
      }
    })
  }, [handleClose, notifications])
  if (!notifications.length) return null
  return (
    <div className={styles.notifications}>
      {notifications.length > 1 && (
        <Button
          label='Cerrar todo'
          size='tiny'
          type='accent-tertiary'
          className={styles.button}
          onClick={handleCloseAll}
        />
      )}
      {notifications.map(({ id, title, message, type, children }) => (
        <div
          className={[
            styles.notification,
            exitingNotifications.includes(id) ? styles.exiting : ''
          ].join(' ')}
          key={id}
        >
          <Notification
            title={title}
            message={message}
            type={type}
            children={children}
            onClose={() => handleClose(id)}
          />
        </div>
      ))}
    </div>
  )
}

export default NotificationStack
