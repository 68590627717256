import * as React from 'react'
const Bot = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 41 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || 'var(--bright)'}
      d='M30.45 30.932h-20a7.5 7.5 0 0 1-7.5-7.5v-10a7.5 7.5 0 0 1 7.5-7.5h20a7.5 7.5 0 0 1 7.5 7.5v10a7.5 7.5 0 0 1-7.5 7.5Zm-20-20a2.5 2.5 0 0 0-2.5 2.5v10a2.5 2.5 0 0 0 2.5 2.5h20a2.5 2.5 0 0 0 2.5-2.5v-10a2.5 2.5 0 0 0-2.5-2.5h-20Z'
    />
    <path
      fill={props.color || 'var(--bright)'}
      d='M15.45 20.932a2.625 2.625 0 0 1-1.775-.725 2.5 2.5 0 0 1 2.25-4.275c.165.023.326.074.475.15.153.05.297.126.425.225l.4.3a2.5 2.5 0 0 1-1.775 4.325ZM25.45 20.932a2.5 2.5 0 0 1-1.775-4.275 2.51 2.51 0 0 1 3.55 3.55 2.495 2.495 0 0 1-.4.3 1.398 1.398 0 0 1-.425.225 1.5 1.5 0 0 1-.475.15l-.475.05ZM20.45 8.432a2.5 2.5 0 0 1-2.5-2.5v-2.5a2.5 2.5 0 1 1 5 0v2.5a2.5 2.5 0 0 1-2.5 2.5ZM12.95 53.432a2.5 2.5 0 0 1-2.5-2.5v-7.5a2.5 2.5 0 1 1 5 0v7.5a2.5 2.5 0 0 1-2.5 2.5ZM27.95 53.432a2.5 2.5 0 0 1-2.5-2.5v-7.5a2.5 2.5 0 1 1 5 0v7.5a2.5 2.5 0 0 1-2.5 2.5Z'
    />
    <path
      fill={props.color || 'var(--bright)'}
      d='M27.95 45.932h-15a2.5 2.5 0 0 1-2.5-2.5v-15a2.5 2.5 0 0 1 2.5-2.5h15a2.5 2.5 0 0 1 2.5 2.5v15a2.5 2.5 0 0 1-2.5 2.5Zm-12.5-5h10v-10h-10v10Z'
    />
    <path
      fill={props.color || 'var(--bright)'}
      d='M2.95 40.932a2.5 2.5 0 0 1-2.366-3.277 2.5 2.5 0 0 1 1.24-1.448l10-5a2.5 2.5 0 1 1 2.25 4.45l-10 5c-.345.185-.732.28-1.124.275ZM37.95 40.932c-.392.005-.78-.09-1.125-.275l-10-5a2.499 2.499 0 1 1 2.25-4.45l10 5a2.5 2.5 0 0 1 .181 4.352 2.5 2.5 0 0 1-1.306.373Z'
    />
  </svg>
)
export default Bot
