//@ts-check
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './LateralMenu.module.css'

const Options = {
  '/': { label: 'Inicio', pathsToCheck: ['/', '/academy'] },
  '/account': { label: 'Cuenta' }
  // '/config': { label: 'Configuración' },
  // '/notifications': { label: 'Notificaciones' }
}

function MenuItem({ to, selected, label }) {
  return (
    <Link to={to}>
      <div className={[styles.item, selected ? styles.selected : ''].join(' ')}>
        <p className={[selected ? styles.selectedText : ''].join(' ')}>
          {label}
        </p>
      </div>
    </Link>
  )
}

function LateralMenu() {
  const { pathname } = useLocation()
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {Object.keys(Options).map(key => {
          const option = Options[key]
          return (
            <MenuItem
              key={key}
              to={key}
              selected={
                key === pathname || option.pathsToCheck?.includes(pathname)
              }
              label={option.label}
            />
          )
        })}
      </div>
    </section>
  )
}

export default LateralMenu
