//@ts-check
import React, { useState, useCallback } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import Svg from 'assets/svg'
import Alert from 'assets/icons/Alert'
import { useAuth } from 'context/AuthProvider'
import { googleLogin } from 'api/auth'
import styles from './Login.module.css'

const { LoginSvg, UupsSvg } = Svg

function makeErrorMessage(e) {
  if (!e || !e.message || !e.code) return 'Error desconocido  '
  return e.message
}

function Login() {
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { saveAuthentication, isAuthenticated } = useAuth()

  const processSuccess = useCallback(
    data => {
      setLoading(false)
      if (!data || typeof data !== 'object') throw new Error('No auth data!')
      saveAuthentication(data)
      navigate('/')
    },
    [saveAuthentication, navigate]
  )
  const processError = useCallback(e => {
    setLoading(false)
    setErrorMessage(makeErrorMessage(e))
  }, [])

  const handleGoogleLogin = useCallback(
    credentials => {
      if (isLoading) return
      setLoading(true)
      googleLogin(credentials).then(processSuccess).catch(processError)
    },
    [isLoading, processError, processSuccess]
  )

  const Img = errorMessage ? UupsSvg : LoginSvg

  if (isAuthenticated) return <Navigate to='/' />
  return (
    <main className={styles.main}>
      <BrightClassfy className={styles.classfyLogo} />
      <div className={styles.images}>
        <Img
          className={styles.backgroundSvg}
          color={errorMessage ? 'var(--sandground-50)' : 'var(--seadapted-50)'}
        />
        <Img className={styles.mainSvg} />
      </div>
      <div className={styles.googleContainer}>
        <GoogleLogin
          text='signin_with'
          onSuccess={handleGoogleLogin}
          onError={processError}
        />
        {errorMessage && (
          <div className={styles.actions}>
            <div className={styles.errorMessage}>
              <Alert className={styles.alertSvg} />
              <p>{errorMessage}</p>
            </div>
          </div>
        )}
      </div>
    </main>
  )
}

export default Login
