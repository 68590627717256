//@ts-check
import React, { useState } from 'react'
import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import CoolAndSmart from 'assets/svg/CoolAndSmart'
import { H2, Paragraph } from 'components/typography'
import TextArea from 'components/inputs/TextArea'
import GalleryModal from 'components/modals/GalleryModal'
import HangingUp from 'components/buttons/HangingUp'
import styles from './ClassroomBot.module.css'
import 'katex/dist/katex.min.css'

const ClassroomBot = ({
  imageLocation,
  answer,
  question,
  isVisible,
  onHangUp
}) => {
  const [showZoom, setShowZoom] = useState(false)
  const formattedContent = answer
    .replaceAll('\\[', '$$')
    .replaceAll('\\]', '$$')
    .replaceAll('\\(', '$')
    .replaceAll('\\)', '$')
  return (
    <div className={styles.container} hidden={!isVisible}>
      <div className={styles.innerContainer}>
        <div
          className={[styles.bot, !imageLocation ? styles.centerHead : ''].join(
            ' '
          )}
        >
          <div className={styles.head}>
            <CoolAndSmart
              className={imageLocation ? styles.svgWithContent : styles.svg}
            />
            <H2>Barbei-bot responde</H2>
            <Paragraph type='body1Bold'>
              Solo podrás visualizar la respuesta del bot cuando el alumno le
              pregunte algo
            </Paragraph>
          </div>

          {imageLocation && (
            <div className={styles.questionArea}>
              <img
                className={styles.img}
                src={imageLocation}
                onClick={() => setShowZoom(true)}
              />
              <TextArea
                placeholder='El alumno no ha dado más información'
                className={styles.textArea}
                value={question}
              />
            </div>
          )}
          <Markdown
            className={styles.markdown}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >
            {formattedContent}
          </Markdown>
        </div>
      </div>

      <div className={styles.tools}>
        <div />
        <HangingUp onHangUp={onHangUp} />
        <div />
      </div>
      {showZoom && (
        <GalleryModal
          initialIndex={0}
          photos={[{ location: imageLocation }]}
          onCancel={() => setShowZoom(false)}
        />
      )}
    </div>
  )
}

export default ClassroomBot
