//@ts-check
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { prop } from 'ramda'
import ReactSelect, { components } from 'react-select'
import ChevronBottom from 'assets/icons/ChevronBottom'
import styles from './Select.module.css'

/**
 * @typedef CustomSelectStyles
 * @property {string} container
 * @property {string} chevronBottom
 * @property {string} label
 * @property {string} select
 * @property {string} selectDisabled
 * @property {string} placeholder
 * @property {string} placeholderDisabled
 * @property {string} menu
 * @property {string} option
 */
/**
 * @typedef SelectProps
 * @property {string} name
 * @property {string | string[]} value
 * @property {string} valueKey
 * @property {string | any} label
 * @property {string} labelKey
 * @property {string} placeholder
 * @property {string[] | {id: string, value: string, label: string}[]} options use transformList for first case
 * @property {number} maxMenuHeight
 * @property {import('react-select').MenuPlacement} menuPlacement
 * @property {boolean} showLabel
 * @property {boolean} showPlaceholder
 * @property {boolean} transformList
 * @property {boolean} isLoading
 * @property {boolean} multiple
 * @property {boolean} required
 * @property {boolean} disabled
 * @property {boolean} isCleareable
 * @property {boolean} autofocus
 * @property {Partial<CustomSelectStyles>} customStyles
 * @property {React.ChangeEventHandler<HTMLSelectElement>} onChange
 */

/** @param {Partial<SelectProps>} props */
function Select({
  name = '',
  value = '',
  valueKey = 'id',
  label = '',
  labelKey = 'label',
  placeholder = 'Seleccione una opción',
  options = [],
  maxMenuHeight = 200,
  menuPlacement = 'auto',
  showLabel = true,
  showPlaceholder = true,
  transformList = false,
  isLoading = false,
  multiple = false,
  required = false,
  disabled = false,
  isCleareable = true,
  autofocus = false,
  customStyles,
  onChange
}) {
  const selectRef = useRef(null)
  const handleChange = useCallback(
    (newValue, actionMeta) => {
      if (disabled) return
      if (multiple)
        return onChange({
          // @ts-ignore
          target: { name, value: newValue.map(prop('value')) }
        })
      // @ts-ignore
      onChange({ target: { name, value: newValue?.value || null } })
    },
    [disabled, multiple, onChange, name]
  )
  const targetOptions = useMemo(() => {
    if (transformList)
      // @ts-ignore
      return options.reduce((acc, option) => {
        acc.push({
          id: option,
          value: option,
          label: option
        })
        return acc
      }, [])
    // @ts-ignore
    return options.reduce((acc, option) => {
      acc.push({
        id: option[valueKey],
        value: option[valueKey],
        label: option[labelKey]
      })
      return acc
    }, [])
  }, [labelKey, options, transformList, valueKey])

  useEffect(() => {
    if (multiple && selectRef.current) selectRef.current.focus()
  }, [multiple, value])
  return (
    <div className={customStyles?.container}>
      <ReactSelect
        ref={selectRef}
        name={name}
        value={
          multiple
            ? targetOptions.filter(option => value.includes(option.id))
            : value
            ? targetOptions.find(option => option.id === value)
            : ''
        }
        onChange={handleChange}
        isMulti={multiple}
        required={required}
        options={targetOptions}
        isLoading={isLoading}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        closeMenuOnSelect={!multiple}
        components={{
          Control: props => (
            <ControlComponent
              {...props}
              label={label}
              showLabel={showLabel}
              customStyles={customStyles}
            />
          ),
          DropdownIndicator: () => (
            <ChevronBottom
              className={[
                styles.chevronBottom,
                customStyles?.chevronBottom
              ].join(' ')}
              disabled={disabled}
            />
          ),
          Placeholder: props =>
            showPlaceholder && (
              <Placeholder {...props} customStyles={customStyles} />
            ),
          Menu: props => <Menu {...props} customStyles={customStyles} />,
          Option: props => <Option {...props} customStyles={customStyles} />
        }}
        isClearable={isCleareable}
        isDisabled={disabled}
        autoFocus={autofocus}
        unstyled
      />
    </div>
  )
}

function ControlComponent(props) {
  return (
    <div>
      {props.label && props.showLabel && (
        <div className={[styles.label, props.customStyles?.label].join(' ')}>
          {props.label}
        </div>
      )}
      <components.Control
        {...props}
        className={[
          styles.select,
          props.isDisabled ? styles.selectDisabled : '',
          props.customStyles?.select,
          props.isDisabled ? props.customStyles?.selectDisabled : ''
        ].join(' ')}
      />
    </div>
  )
}

function Placeholder(props) {
  return (
    <components.Placeholder
      {...props}
      children={<span>{props.children}</span>}
      className={[
        styles.placeholder,
        props.isDisabled ? styles.placeholderDisabled : '',
        props.customStyles?.placeholder,
        props.isDisabled ? props.customStyles?.placeholderDisabled : ''
      ].join(' ')}
    />
  )
}

function Menu(props) {
  return (
    <components.Menu
      {...props}
      className={[styles.menu, props.customStyles?.menu].join(' ')}
    >
      {props.children}
    </components.Menu>
  )
}

function Option(props) {
  return (
    <components.Option
      {...props}
      className={[
        styles.option,
        props.isSelected ? styles.optionSelected : '',
        props.customStyles?.option
      ].join(' ')}
    />
  )
}

export default Select
