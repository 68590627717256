import * as React from 'react'
const GraphBar = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#000'}
      fillRule='evenodd'
      d='M15 5h-1a2 2 0 0 0-2 2v16h-1V12a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v11H5a1 1 0 1 0 0 2h20a1 1 0 1 0 0-2h-2v-8a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v8h-1V7a2 2 0 0 0-2-2Zm-1 2v16h1V7h-1Zm-6 5v11h1V12H8Zm12 11v-8h1v8h-1Z'
      clipRule='evenodd'
    />
    <mask
      id='GraphBar'
      width={22}
      height={20}
      x={4}
      y={5}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'luminance'
      }}
    >
      <path
        fill={props.color || '#fff'}
        fillRule='evenodd'
        d='M15 5h-1a2 2 0 0 0-2 2v16h-1V12a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v11H5a1 1 0 1 0 0 2h20a1 1 0 1 0 0-2h-2v-8a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v8h-1V7a2 2 0 0 0-2-2Zm-1 2v16h1V7h-1Zm-6 5v11h1V12H8Zm12 11v-8h1v8h-1Z'
        clipRule='evenodd'
      />
    </mask>
    <g mask='url(#GraphBar)'>
      <path fill={props.color || '#8080F8'} d='M0 0h30v30H0z' />
    </g>
  </svg>
)
export default GraphBar
