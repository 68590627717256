import React from 'react'
import styles from './StatusIndicator.module.css'

function StatusIndicator({ success }) {
  return (
    <div
      className={[
        styles.statusCircle,
        success ? styles.success : styles.error
      ].join(' ')}
    />
  )
}

export default StatusIndicator
