// @ts-check
import React, { createContext, useContext, useMemo, useState } from 'react'
import { noop } from 'utils'

const NotificationContext = createContext([
  {
    id: '',
    title: '',
    message: '',
    type: '',
    children: null,
    ms: 6000
  }
])

const NotificationActionsContext = createContext({
  clear: noop,
  setErrorMessage: noop,
  setSuccessMessage: noop,
  setInfoMessage: noop,
  setWarningMessage: noop
})

function NotificatioProvider({ children }) {
  const [notifications, setNotifications] = useState([])

  const addNotification = ({ title, message, type, children, ms }) => {
    setNotifications(prev => {
      const sum = prev.reduce((acc, n) => acc + n.ms, 0)
      const id = Date.now() + prev.length + sum
      const newTime = sum / (prev.length + 1) + ms
      const newNotifications = [
        ...prev,
        { id, title, message, type, children, ms: newTime }
      ]
      setTimeout(() => {
        setNotifications(prev => prev.filter(n => n.id !== id))
      }, newTime)
      return newNotifications
    })
  }
  const value = useMemo(
    () => ({
      clear: id =>
        setNotifications(prev =>
          prev.filter(notification => notification.id !== id)
        ),
      setErrorMessage: ({
        title = 'Error',
        message = 'Se ha producido un error',
        children,
        ms = 7000
      }) => addNotification({ title, message, type: 'error', children, ms }),
      setSuccessMessage: ({
        title = 'Éxito',
        message = 'Guardado correctamente',
        children,
        ms = 6000
      }) => addNotification({ title, message, type: 'success', children, ms }),
      setInfoMessage: ({
        title = 'Información',
        message,
        children,
        ms = 6000
      }) => addNotification({ title, message, type: 'info', children, ms }),
      setWarningMessage: ({
        title = 'Atención',
        message,
        children,
        ms = 6000
      }) => addNotification({ title, message, type: 'warning', children, ms })
    }),
    []
  )

  return (
    <NotificationContext.Provider value={notifications}>
      <NotificationActionsContext.Provider value={value}>
        {children}
      </NotificationActionsContext.Provider>
    </NotificationContext.Provider>
  )
}

export default NotificatioProvider

/**
 *
 * @returns {{
 * id: string,
 * title: string,
 * message: string,
 * type: import('components/notifications/Notification').NotificationProps['type'] | string
 * children: any,
 * ms: number
 * }[]}
 */
export const useNotification = () => useContext(NotificationContext)

/**
 * @typedef CommomNotificationParams
 * @property {string} title
 * @property {string | any} message
 * @property {any} [children]
 * @property {number} [ms]
 */
/**
 *
 * @returns {{
 * clear: (id: string) => void
 * setErrorMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setSuccessMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setInfoMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * setWarningMessage: ({title, message, children, ms}:Partial<CommomNotificationParams>) => void
 * }}
 */
export const useNotificationActions = () =>
  useContext(NotificationActionsContext)
