import * as React from 'react'
const Activity = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#000'}
      fillRule='evenodd'
      d='M6.284 16.994c.347 0 .67-.185.852-.488l1.09-1.817 2.213 4.73a.991.991 0 0 0 1.835-.075l2.607-6.926 2.233 4.055c.177.322.51.52.871.52h1.268c.519 1.316 1.777 2.243 3.247 2.243 1.933 0 3.5-1.604 3.5-3.583 0-1.978-1.567-3.582-3.5-3.582-1.697 0-3.111 1.236-3.432 2.876h-.499L15.581 9.52a.99.99 0 0 0-1.804.135l-2.536 6.738-2.005-4.285a.99.99 0 0 0-1.754-.094l-1.758 2.932H5c-.552 0-1 .458-1 1.023s.448 1.024 1 1.024h1.284Zm16.216.195c.828 0 1.5-.688 1.5-1.536 0-.848-.672-1.535-1.5-1.535s-1.5.688-1.5 1.535c0 .848.672 1.536 1.5 1.536Z'
      clipRule='evenodd'
    />
    <mask
      id='Activity'
      width={22}
      height={11}
      x={4}
      y={9}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'luminance'
      }}
    >
      <path
        fill={props.color || '#fff'}
        fillRule='evenodd'
        d='M6.284 16.994c.347 0 .67-.185.852-.488l1.09-1.817 2.213 4.73a.991.991 0 0 0 1.835-.075l2.607-6.926 2.233 4.055c.177.322.51.52.871.52h1.268c.519 1.316 1.777 2.243 3.247 2.243 1.933 0 3.5-1.604 3.5-3.583 0-1.978-1.567-3.582-3.5-3.582-1.697 0-3.111 1.236-3.432 2.876h-.499L15.581 9.52a.99.99 0 0 0-1.804.135l-2.536 6.738-2.005-4.285a.99.99 0 0 0-1.754-.094l-1.758 2.932H5c-.552 0-1 .458-1 1.023s.448 1.024 1 1.024h1.284Zm16.216.195c.828 0 1.5-.688 1.5-1.536 0-.848-.672-1.535-1.5-1.535s-1.5.688-1.5 1.535c0 .848.672 1.536 1.5 1.536Z'
        clipRule='evenodd'
      />
    </mask>
    <g mask='url(#Activity)'>
      <path fill={props.color || '#8080F8'} d='M0 0h30v30H0z' />
    </g>
  </svg>
)
export default Activity
