import * as React from 'react'
const Copy = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 39 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#000'}
      fillRule='evenodd'
      stroke='#979797'
      strokeWidth={0.234}
      d='M1.064 11.77c0-1.966 1.521-3.49 3.812-3.49 2.29 0 6.99-.052 6.99-.052s-.07-1.638-.07-3.722C11.795 2.423 13.658 1 15.893 1c2.236 0 13.31.094 14.396.094 1.087 0 1.968.403 3.186 1.329 1.218.926 3.06 2.475 3.971 3.507.912 1.031 1.296 1.634 1.296 2.485s0 18.013-.014 20.002c-.013 1.988-1.846 3.458-4.015 3.487-2.17.03-6.829.03-6.829.03s.11 1.47.11 3.55-1.9 3.706-4.06 3.763c-2.158.058-16.861-.115-18.956-.057C2.883 39.247 1 37.597 1 35.575c0-2.022.064-21.838.064-23.804Zm3.922.925-.27 21.763a.928.928 0 0 0 .928.94l17.323.052a.904.904 0 0 0 .917-.933l-.051-2.084a.467.467 0 0 0-.48-.457s-5.125.15-7.445.068c-2.321-.081-3.908-1.595-3.909-3.636L12 12.203a.465.465 0 0 0-.47-.467l-5.595.019a.955.955 0 0 0-.949.94ZM29.95 4.432c-.39-.34-.707-.197-.707.322v4.044c0 .259.21.47.468.471l4.891.024c.517.003.62-.27.229-.61l-4.88-4.251Zm-13.501.198a.47.47 0 0 0-.474.466l-.146 22.569a.695.695 0 0 0 .698.702l17.625-.044a.48.48 0 0 0 .478-.471l.298-14.63a.457.457 0 0 0-.46-.47s-6.134.01-7.214.01c-1.081 0-1.766-.52-1.766-1.52 0-.998-.021-6.085-.021-6.085a.476.476 0 0 0-.472-.473l-8.547-.054Z'
      clipRule='evenodd'
    />
  </svg>
)
export default Copy
