import React from 'react'
import styles from './Anchor.module.css'

/**
 * @typedef AnchorProps
 * @property {'primary' | 'secondary' |'tertiary'} type
 * @property {string} className
 * @property {string} target
 * @property {string} href
 * @property {any} children
 *
 */

/** @param {Partial<AnchorProps>} props */
function Anchor({
  type = 'primary',
  className = '',
  target = '_blank',
  href = '',
  children = null
}) {
  return (
    <a
      className={[styles.a, styles[type], className].join(' ')}
      target={target}
      href={href}
    >
      {children}
    </a>
  )
}

export default Anchor
