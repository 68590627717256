import * as React from 'react'

const Success = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 512 512'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || 'var(--dark-grey-40)'}
      fillRule='evenodd'
      d='M256 42.667C138.18 42.667 42.667 138.18 42.667 256S138.18 469.333 256 469.333 469.333 373.82 469.333 256 373.82 42.667 256 42.667Zm80.336 137.114 30.167 30.167-131.836 132.388-79.084-79.084 30.167-30.166 48.917 48.917L336.336 179.78Z'
    />
  </svg>
)

export default Success
